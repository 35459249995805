import React from "react";
import { FormattedHTMLMessage } from "gatsby-plugin-intl";
import { Layout } from "../components";

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <FormattedHTMLMessage id="dsgvo" />
    </Layout>
  );
};

export default PrivacyPolicyPage;
